<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="white pa-0"
  >
    <v-row>
      <!-- Section 1 -->
      <v-col>
        <hero-component
          :btn-text="$t('bnm.getStartedButton')"
          btn-color="#00A1B7"
          btn-text-color="#FFFFFF"
          background-url="/img/dashboard/Heroimage.jpg"
          :background-position="backgroundPosition"
          :background-size="backgroundSize"
          :heading-font-size="smDeviceAndBelow ? '48px' : xlDevice ?'100px' : '52px'"
          :is-centered="true"
          gap="0px"
          @click="scrollToSection2"
          class="hero-image"
        >
          <v-row class="d-flex justify-left">
            <v-col
              class="mt-12"
              cols="10"
              lg="9"
              sm="12"
            >
              <p
                class="text-left"
                :style="{
                  fontSize:$i18n.locale==='fr'?'2.8rem':'inherit'
                }"
              >
                {{ $t("bnm.coverageConfidenceTagline") }}
              </p>
              <p
                class="section1Subheading text-left"

                :style="{fontSize: xlDevice ? '40px' : '24px', lineHeight: xlDevice ? '40px':'25px',marginTop:'30px'}"
              >
                {{ $t("bnm.introText") }}
              </p>
            </v-col>
          </v-row>
        </hero-component>
      </v-col>
      <!-- Section 2 -->
      <v-row class="d-flex justify-center">
        <v-col
          class="d-flex justify-center"
          cols="8"
        >
          <h1 class="mt-12 text-alignment">
            {{ $t("bnm.coverageOptionsIntro") }}
          </h1>
        </v-col>
        <v-col
          cols="11"
          xl="9"
        >
          <v-row
            v-if="productCardsCA"
            class="mb-12"
          >
            <v-col
              v-for="(product, i) in productCardsCA"
              :key="product.id"
              class="d-flex justify-center"
              cols="12"
              lg="3"
              sm="6"
            >
              <v-card
                elevation="0"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <div>
                      <v-img
                        class="imageContainer"
                        :src="product.img"
                      >
                      </v-img>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <h3>
                      {{ product.title }}
                    </h3>
                  </v-col>
                  <v-col
                    class="d-flex justify-center"
                  >
                    <p class="section2-product-text text-alignment">
                      {{ product.txt }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          <v-col
          cols="12"
          class="d-flex justify-center"
          >
          <router-link to="/contactus-ca">
            <v-btn
              color="primary"
              class="font-weight-bold mr-0"
              large
            >
              {{ this.$t('contactAdvsior') }}
            </v-btn>
          </router-link>
        </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Section 3 -->
      <v-row class="d-flex justify-center section3">
        <v-col
          cols="8"
          sm="10"
        >
          <h1 class="section3-title  text-alignment mt-12 mb-4">
            {{ $t('bnm.lifestyleCoverageTagline') }}
          </h1>
        </v-col>
        <v-col
          cols="12"
          xl="11"
        >
          <v-row
            v-if="productsIcons"
            class="pa-16"
          >
            <v-col
              v-for="(product, i) in productsIcons.icons"
              :key="product.id"
              class="d-flex justify-center"
              cols="6"
              lg="3"
              md="3"
              sm="3"
            >
              <v-col
                cols="12"
                class="d-flex flex-column  align-center"
              >
                <div class="imageContainerSection3">
                  <img
                    :src="product.icon"
                  >
                </div>
                <h4 class="section3-iconTitle text-alignment mt-2">
                  {{ product.title }} <br> {{ productsIcons.insurance }}
                </h4>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Section 4 -->
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <h1
            class="section4-title d-flex justify-center mt-12"
            :style="{
              fontSize:$i18n.locale==='fr'?'28px':'inherit'
            }"
          >
            {{ $t('about.aboutBlanket.title') }}
          </h1>
        </v-col>
        <v-col
          cols="8"
          class="mb-12"
        >
          <p class="section4-text text-alignment">
            {{ $t('about.aboutBlanket.description') }}
          </p>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
  import HeroComponent from '@/components/base/Hero'

  export default {
    name: 'Dashboard',
    components: {
      HeroComponent
    },
    data () {
      return {
        id: '',
        symetrapdf: '',
        tabs: 0,
        model: null,
        show: false,
        productCards: [],
        categories: [],
        showncategories: [],
        filteredproducts: [],
        getStarted: false,
        showTitle: true,
        currentCountry: 'US'
      }
    },
    computed: {
      userCanadian () {
        this.productCards = this.productCardsUSA
        if (this.$store.getters.getWebsiteLocation === 'CA') {
          this.productCards = this.productCardsCA
          return true
        }
        return false
      },
      getuserkey () {
        let key = ''
        key = this.$store.getters.getcurrentuserkey
        return key
      },
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      lgDevice () {
        return this.$vuetify.breakpoint.lgOnly
      },
      mdDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
      smDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      xsDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smDeviceAndBelow () {
        return this.$vuetify.breakpoint.smAndDown
      },
      productlist () {
        return this.filteredproducts
      },
      backgroundPosition () {
        if (this.xlDevice) {
          return '-150px -620px'
        } else if (this.lgDevice) {
          return '0px -318px'
        } else if (this.smDevice || this.mdDevice) {
          return '-100px -20px'
        } else {
          return '-530px -200px'
        }
      },
      backgroundSize () {
        if (this.xlDevice) {
          return '110%'
        } else if (this.lgDevice) {
          return 'cover'
        } else if (this.mdDevice || this.smDevice) {
          return '120%'
        } else {
          return '370%'
        }
      },
      productCardsCA () {
        return [
          {
            title: this.$t('bnm.insuranceOptions.lifeInsurance'),
            id: 1,
            // route: '/travel/ca',
            // learnRoute: '/travel/ca',
            img: '/img/dashboard/Life.jpg',
            // tag: 'dashboard.section3.tiles.travel.desc',
            txt:
              this.$t('bnm.lifeInsuranceDescription'),
          },
          {
            title: this.$t('bnm.insuranceOptions.businessInsurance'),
            id: 2,
            // route: '/pet-ca',
            // learnRoute: '/pet-ca',
            img: '/img/dashboard/Business.jpg',
            // tag: 'dashboard.section3.tiles.pet.desc',
            txt:
              this.$t('bnm.commitmentMessage'),
          },
          {
            title: this.$t('bnm.insuranceOptions.autoInsurance'),
            id: 3,
            // route: '#',
            // learnRoute: '#',
            img: '/img/dashboard/auto.jpg',
            // tag: 'dashboard.section3.tiles.condo.desc',
            txt: this.$t('bnm.autoInsuranceDescription'),
          },
          {
            title: this.$t('bnm.insuranceOptions.propertyInsurance'),
            id: 4,
            // route: '#',
            // learnRoute: '#',
            img: '/img/dashboard/Property.jpg',
            // tag: 'dashboard.section3.tiles.renters.desc',
            txt: this.$t('bnm.propertyInsuranceDescription'),
          }
        ]
      },
      productsIcons () {
        return {
          insurance: '',
          icons: [
            {
              id: 1,
              title: this.$t('bnm.additionalInsuranceOptions.homeInsurance'),
              icon: '/img/product-cards/home.png'
            },
            {
              id: 2,
              title: this.$t('bnm.additionalInsuranceOptions.commercialInsurance'),
              icon: '/img/product-cards/condo.png'
            },
            {
              id: 3,
              title: this.$t('bnm.insuranceOptions.businessInsurance'),
              icon: '/img/product-cards/business.png'
            },
            {
              id: 4,
              title: this.$t('bnm.insuranceOptions.lifeInsurance'),
              icon: '/img/product-cards/life.png'
            },
            {
              id: 5,
              title: this.$t('bnm.additionalInsuranceOptions.petInsurance'),
              icon: '/img/product-cards/pet.png'
            },
            {
              id: 6,
              title: this.$t('bnm.additionalInsuranceOptions.travelInsurance'),
              icon: '/img/product-cards/travel.png'
            },
            {
              id: 7,
              title: this.$t('bnm.additionalInsuranceOptions.rentersInsurance'),
              icon: '/img/product-cards/renter.png'
            },
            {
              id: 8,
              title: this.$t('bnm.insuranceOptions.autoInsurance'),
              icon: '/img/product-cards/auto.png'
            },
          ]
        }
      }
    },
    created () {
      if (this.xlDevice) {
        console.log('xlDevice')
      } else if (this.lgDevice) {
        console.log('lgDevice')
      } else if (this.mdDevice) {
        console.log('mdDevice')
      } else if (this.smDevice) {
        console.log('smDevice')
      }
    },
    methods: {
      scrollToSection2 () {
        this.$router.push('/contactus-ca')
      },
      transition () {
        this.getStarted = !this.getStarted
        setTimeout(() => (this.showTitle = !this.showTitle), 400)
      },
    }
  }
</script>
<style scoped>
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
}

.text-alignment {
  text-align: center;
}
.section1Subheading {
  font-weight: normal;
  /* font-size: 23px; */
  line-height: 25px;
  color:#ffffff;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 20); */
}

.section2 {
  position: relative;
  height: 831.86px;
  background: #ffffff;
}

.section2-product-text{
  font-size: 18px;
}

.imageContainer {
  width: 250px;
  height: 188px;
}
.imageContainerSection3 {
  background-color: white;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 50%;
}

.imageContainerSection3 img {
  transform: scale(0.6) !important;
}

.section3 {
  color: white;
  background: #00A1B7;
}

.section3-title {
  font-family: "Sora";
  font-style: normal;
  font-size: 40px;
}
.section3-iconTitle {
  font-size:23px;
}

.section4-title {
  font-family: "Sora";
  font-style: normal;
  font-size: 35px;
}

.section4-text {
  font-style: normal;
  font-size: 18px;
}
.sora {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
}
.hero-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.2;
  z-index: -1;
}

.hero-image {
  position: relative;
  z-index: 1;
}
</style>
